export const UI_LABELS_KEYS = {
  HELLO: 'HELLO',
  GOOD: 'GOOD',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  NOT_A_MEMBER: 'NOT_A_MEMBER',
  EMAIL: 'EMAIL',
  PASSWORD: 'PASSWORD',
  LOGIN: 'LOGIN',
  SIGN_UP: 'SIGN_UP',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  PASSWORD_IS_REQUIRED: 'PASSWORD_IS_REQUIRED',
  EMAIL_IS_REQUIRED: 'EMAIL_IS_REQUIRED',
  MORNING: 'MORNING',
  AFTERNOON: 'AFTERNOON',
  EVENING: 'EVENING',
  JANUARY: 'JANUARY',
  FEBRUARY: 'FEBRUARY',
  MARCH: 'MARCH',
  APRIL: 'APRIL',
  MAY: 'MAY',
  JUNE: 'JUNE',
  JULY: 'JULY',
  AUGUST: 'AUGUST',
  SEPTEMBER: 'SEPTEMBER',
  OCTOBER: 'OCTOBER',
  NOVEMBER: 'NOVEMBER',
  DECEMBER: 'DECEMBER',
  SUNDAY: 'SUNDAY',
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY',
  ADD_SHIFT: 'ADD_SHIFT',
  PHONE_NUMBER: 'PHONE_NUMBER',
  CLAIM_NOTIFICATION: 'CLAIM_NOTIFICATION',
  CANCEL_NOTIFICATION: 'CANCEL_NOTIFICATION',
  LOGOUT: 'LOGOUT',
  CANCEL: 'CANCEL',
  CLAIM: 'CLAIM',
  NOTIFICATION_SETTING: 'NOTIFICATION_SETTING',
  CLAIM_CONFIRMATION: 'CLAIM_CONFIRMATION',
  ENTER_PHONE_NUMBER: 'ENTER_PHONE_NUMBER',
  POSITIONS: 'POSITIONS',
  STORE_LOCATION: 'STORE_LOCATION',
  RADIUS: 'RADIUS',
  SAVE: 'SAVE',
  AVAILABLE: 'AVAILABLE',
  START_DATE_TIME: 'START_DATE_TIME',
  END_DATE_TIME: 'END_DATE_TIME',
  POSITION: 'POSITION',
  START_TIME: 'START_TIME',
  END_TIME: 'END_TIME',
  CANCEL_SHIFT: 'CANCEL_SHIFT',
  EDIT_RADIUS: 'EDIT_RADIUS',
  DATE: 'DATE',
  TIME: 'TIME',
  NEXT: 'NEXT',
  PREVIOUS: 'PREVIOUS',
  THIS_WEEK: 'THIS_WEEK',
  NO_SHIFT_FOUND: 'NO_SHIFT_FOUND',
  NO_NOTIFICATION_FOUND: 'NO_NOTIFICATION_FOUND',
  YES: 'YES',
  NO: 'NO',
  OK: 'OK',
  FAIL_TO_LOAD_DATA: 'FAIL_TO_LOAD_DATA',
  SHIFT_START_DATE: 'SHIFT_START_DATE',
  SHIFT_END_DATE: 'SHIFT_END_DATE',
  START_TIME_GREATER_THAN_END_TIME: 'START_TIME_GREATER_THAN_END_TIME',
  END_TIME_GREATER_THAN_START_TIME: 'END_TIME_GREATER_THAN_START_TIME',
  FIELD_REQUIRED: 'FIELD_REQUIRED',
  DATE_SHOULD_NOT_GREATER_THAN_1_DAY: 'DATE_SHOULD_NOT_GREATER_THAN_1_DAY',
  DONE: 'DONE',
  CANCEL_CONFIRMATION: 'CANCEL_CONFIRMATION',
  RECLAIM_SHIFT: 'RECLAIM_SHIFT',
  UNAVAILABLE: 'UNAVAILABLE',
  ZIP_CODE:'ZIP_CODE',
  SHIFT_TYPE_LABEL:'SHIFT_TYPE_LABEL'
}

export const VALIDATION_MESSAGES = {
  PHONE: 'Please input your phone number!',
}

export const ROUTES = {
  NOTIFICATIONS: '/notifications', // IN FUTURE'/notifications',
  SHIFT_SEARCH: '/search-shifts',
  PROFILE: '/profile',
  SHIFTS: '/shifts',
  ADD_SHIFT: '/add-shift',
  MONTHLY_SHIFTS: '/monthly-shifts',
  CALENDAR: '/calendar',
  LOG_IN: '/login',
  LOGOUT: '/logout',
  LAYOUT: '/',
  EDIT_RADIUS: '/edit-radius',
  AUTHORIZE: '/authorize',
}

export const ROLES_KEYS = {
  MANAGER: 'MANAGER',
  MEMBER: 'MEMBER',
}

export const ROLES = {
  SUPER_ADMIN: { value: 'SUPER_ADMIN', label: 'Super Admin' },
}

export const SHIFT_COLORS = ['#B463F0', '#45AFE6', '#22CCA5']

export const SHIFT_CLASSES_BY_STATUS = {
  PENDING: 'pending',
  CLAIMED: 'claimed',
  CANCELLED: 'cancelled',
}

export const SHIFT_STATUS = {
  CLAIMED: { value: 'CLAIMED', label: 'Claimed' },
  PENDING: { value: 'PENDING', label: 'Pending' },
  CANCELLED: { value: 'CANCELLED', label: 'Cancelled' },
}

export const RADIUS_OPTIONS = [
  { value: 1, label: '1 mi' },
  { value: 3, label: '3 mi' },
  { value: 5, label: '5 mi' },
  { value: 10, label: '10 mi' },
  { value: 15, label: '15 mi' },
  { value: 20, label: '20 mi' },
  { value: 25, label: '25 mi' },
  { value: 50, label: '50 mi' },
  { value: 75, label: '75 mi' },
  { value: 100, label: '100 mi' },
]

export const GMT_LOGIN_URL =
  'https://greenfenceb2c.b2clogin.com/greenfenceb2c.onmicrosoft.com/B2C_1A_SignUpOrSignInWithPhoneGotShift/oauth2/v2.0/authorize?client_id=baa9dbf9-5851-4c15-9d22-e0c016322612&redirect_uri=https://kroger.gotshift.io/authorize&response_type=code&scope=baa9dbf9-5851-4c15-9d22-e0c016322612%20offline_access&response_mode=query'

export const GMT_LOGOUT_URL =
  'https://greenfenceb2c.b2clogin.com/greenfenceb2c.onmicrosoft.com/b2c_1a_signuporsigninwithphonegotshift/oauth2/v2.0/logout?post_logout_redirect_uri=https://kroger.gotshift.io/authorize'

export const DEFAULT_LANGUAGE = 'EN'
